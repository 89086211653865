/*
 *  @Author: Corn-czy
 * @Date: 2022-06-22 10:23:56
 * @Description: file content
 * @FilePath: \zm-data-platform-admin\src\store\index.js
 */
import Vue from "vue";
import Vuex from "vuex";
import { login } from "@/api/login.js";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    menu: [],
    token: "",
    asyncRouters: [],
  },
  mutations: {
    SET_MENU: (state, menu) => {
      state.menu = menu;
    },
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    RESET_STATE: (state) => {
      state.menu = [];
      state.token = "";
    },
    SET_ROUTERS: (state, asyncRouters) => {
      state.asyncRouters = asyncRouters;
    },
  },
  actions: {
    //1、修改login方法
    login({ commit }, userInfo) {
      return new Promise(async (resolve, reject) => {
        let data = await login(userInfo);
        if (data.status) {
          localStorage.setItem("account", userInfo.account);
          sessionStorage.setItem("token", data.token);
          await commit("SET_TOKEN", data.data.token);
        }
        resolve(data);
      }).catch((err) => {
        reject(err);
      });
    },

    //2、添加一个方法用来修改 mutations，这个方法是跟login方法同级的
    modifyMenu({ commit }, menu) {
      return new Promise((resolve) => {
        commit("SET_MENU", menu);
        resolve();
      });
    },
    asyncRouters({ commit }, arr) {
      commit("SET_ROUTERS", arr);
    },
    //登出
    loginOut({ commit }, state) {
      return new Promise(async (resolve, reject) => {
        localStorage.removeItem("account");
        sessionStorage.removeItem("token");
        location.reload();
        commit("RESET_STATE");
        resolve();
      }).catch((err) => {
        reject(err);
      });
    },
  },
  modules: {},
  getters: {},
});
