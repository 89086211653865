/*
 * @Author: Corn-czy
 * @Date: 2022-05-10 14:35:23
 * @Description: file content
 * @FilePath: \zm-data-platform-admin\src\router\index.js
 */
import Layout from '@/layout' //引入admin的layout布局
import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/login/index.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Login',
        component: Login,
    },
    /* ,
    {
        path: '/welcome',
        name: 'Equipment',
        component: Layout,
        meta: {
            title: "welcome",
            icon: "el-icon-video-camera-solid",
            index: "1",
        },
        children: [{
            path: '/welcome',
            component: Welcome,
            meta: {
                title: "欢迎页",
            },
        }]
    } */
    {
        path: '/equipment',
        name: 'Equipment',
        component: Layout,
        meta: {
            title: '设备数据',
            icon: 'el-icon-video-camera-solid',
            index: '1',
        },
        children: [
            {
                path: '/equipment',
                component: () => import('../views/equipment/index.vue'),
            },
        ],
    },
    /**{
        path: '/content',
        name: 'Content',
        component: Layout,
        meta: {
            title: '内容数据',
            icon: 'el-icon-s-order',
            index: '2',
        },
        children: [
            {
                path: '/content',
                component: () => import('../views/content/index.vue'),
            },
        ],
    },
    {
        path: '/shadow',
        name: 'Shadow',
        component: Layout,
        meta: {
            title: '影子数据',
            icon: 'el-icon-s-marketing',
            index: '3',
        },
        children: [
            {
                path: '/shadow',
                component: () => import('../views/shadow/index.vue'),
            },
        ],
    },
    {
      path: '/users',
      name: 'Users',
      component: Layout,
      meta: {
          title: "用户数据",
          icon: "el-icon-s-custom",
          index: "4",
      }, children: [{
          path: '/users',
          component: () => import('../views/user/index.vue'),

      }]
  },
  {
      path: '/datas',
      name: 'Datas',
      component: Layout,
      meta: {
          title: "数据分析",
          icon: "el-icon-s-data",
          index: "5",
      }, children: [{
          path: '/datas',
          component: () => import('../views/data/index.vue'),

      }]
  }*/

    {
        path: '/equdatas',
        name: 'equDatas',
        component: Layout,
        meta: {
            title: '设备管理',
            icon: 'el-icon-s-data',
            index: '5',
        },
        children: [
            {
                path: '/equdatas',
                component: () => import('../views/equDatas/index.vue'),
            },
            {
                path: '/equdatasDetail',
                component: () => import('../views/equDatas/components/detail.vue'),
            },
        ],
    },
]

// process.env.VUE_APP_ENV == "test" ? routes.splice(1, 2) : false;
const router = new VueRouter({
    routes,
})

export default router
