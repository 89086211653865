<!--
 * @Author: Corn-czy
 * @Date: 2022-06-21 16:25:09
 * @Description: file content
 * @FilePath: \zm-data-platform-admin\src\views\login\index.vue
-->
<template>
    <div class="loginBg">
        <div class="flex">
            <img src="../../assets/leftpic.png" alt="" class="leftImg" />
            <div class="form">
                <el-form ref="loginForm" :model="loginForm" :rules="loginRules" @submit.native.prevent
                    class="login-form" auto-complete="on" label-position="left">
                    <p class="title">知麻 数据平台</p>
                    <el-form-item prop="account">
                        <el-input placeholder="请输入您的账号" prefix-icon="el-icon-user" v-model="loginForm.account" />
                    </el-form-item>
                    <el-form-item prop="password">
                        <el-input placeholder="请输入您的密码" show-password prefix-icon="el-icon-lock"
                            v-model="loginForm.password" type="password" />
                    </el-form-item>
                    <el-form-item prop="captcha">
                        <div class="codeBox">
                            <el-input placeholder="请输入验证码" v-model="loginForm.captcha" maxlength="4" class="code"
                                @keyup.enter.native="loginBtn" />
                            <img :src="getCaptcha" alt="" class="codeImg" @click="restUnique" />
                        </div>
                    </el-form-item>
                    <div>
                        <el-checkbox v-model="checked" @change="changeChecked">记住账号密码</el-checkbox>
                    </div>
                    <el-button class="loginBtn" :loading="loading" type="primary"
                        :style="loginForm.captcha && loginForm.account && loginForm.password ? '' : ' background: #7B90EB;'"
                        @click.native.prevent="loginBtn">
                        登录
                    </el-button>
                    <p class="tips">账号、密码及权限问题请与管理员联系</p>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Login',
    data() {
        return {
            checked: '',
            loginForm: {
                account: '',
                password: '',
                unique: new Date().getTime().toString(), // 客户端唯一识别码
                captcha: '', // 验证码
            },
            loading: false,
            loginRules: {
                account: [
                    {
                        required: true,
                        message: '请输入你的账号',
                        trigger: 'blur',
                    },
                ],
                password: [
                    {
                        required: true,
                        message: '请输入你的密码',
                        trigger: 'blur',
                    },
                ],
                captcha: [
                    {
                        required: true,
                        message: '请输入验证码',
                        trigger: 'blur',
                    },
                ],
            },
        }
    },
    computed: {
        getCaptcha() {
            return `${process.env.VUE_APP_URL}/login/captcha.json?unique=${this.loginForm.unique}`
        },
    },
    async mounted() {
        this.checked = JSON.parse(localStorage.getItem('user')) ? true : false
        if (this.checked) {
            let userObj = JSON.parse(localStorage.getItem('user'))
            this.loginForm.account = userObj.account
            this.loginForm.password = userObj.password
        }
    },
    methods: {
        //换一张
        async restUnique() {
            this.loginForm.unique = new Date().getTime().toString()
        },
        loginBtn() {
            this.$refs.loginForm.validate(async (valid) => {
                if (valid) {
                    this.loading = true
                    try {
                        if (this.checked) {
                            localStorage.setItem(
                                'user',
                                JSON.stringify({
                                    account: this.loginForm.account,
                                    password: this.loginForm.password,
                                })
                            )
                        } else {
                            localStorage.removeItem('user')
                        }
                        let data = await this.$store.dispatch('login', this.loginForm)
                        if (data.status) {
                            //跳转路由数组的第一个路由
                            this.$router.push({
                                path: '/equipment',
                            })
                            this.loading = false
                        } else {
                            this.restUnique()
                            this.loading = false
                            this.$Message({
                                message: data.msg,
                                type: 'warning',
                            })
                        }
                    } catch (error) {
                        this.restUnique()
                        this.loading = false
                    }
                } else {
                    return false
                }
            })
        },
        changeChecked(e) {
            this.checked = e
        },
    },
}
</script>

<style lang="scss" scoped>
::v-deep .el-button--primary {
    width: 512px;
    height: 50px;
    margin-top: 80px;
    background: #4318ff;
    border-radius: 88px;
    border: none;
}

::v-deep .el-input__prefix {
    left: 0;
}

::v-deep .el-checkbox__label {
    color: rgba(0, 0, 0, 0.4);
}

::v-deep .el-checkbox__input.is-checked+.el-checkbox__label {
    color: #4318ff;
}

::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #4318ff;
    border-color: #4318ff;
}

::v-deep .el-checkbox {
    transform: scale(1.1);
    margin-left: 4px;
}

::v-deep .el-input__icon {
    font-size: 18px;
}

::v-deep .el-input__inner {
    height: 44px !important;
    font-size: 18px;
    border: none;
    border-radius: 0;
    border-bottom: solid rgba(0, 0, 0, 0.1) 1px;
}

::v-deep .el-input {
    width: 512px;
}

::v-deep .el-checkbox__label {
    font-size: 12px !important;
}

.loginBg {
    width: 100%;
    height: 100%;
    background: url('https://hsl-zeemr.oss-accelerate.aliyuncs.com/bgImage/dp_LoginBg.png') no-repeat 100% 100%;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;

    .flex {
        width: 85%;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }

    .leftImg {
        width: 50%;
        min-width: 50%;
    }

    .form {
        background: #fff;
        border-radius: 2%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .login-form {
            margin: 0 30px;
        }

        .title {
            margin-bottom: 70px;
            font-size: 64px;
            font-weight: 600;
            line-height: 90px;
            color: #3857e1;
        }

        .codeBox {
            width: 512px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .codeImg {
                width: 168px;
                height: 60px;
                border: 1px solid #999;
                margin-left: 16px;
                margin-top: -16px;
                margin-right: 12px;
                overflow: hidden;
            }
        }

        .code {
            width: 360px;
        }

        .tips {
            width: 512px;
            margin-top: 10px;
            margin-bottom: 20px;
            font-weight: 400;
            font-size: 16px;
            text-align: center;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.2);
        }
    }
}
</style>
