<!--
 * @Author: Corn-czy
 * @Date: 2022-03-28 14:31:14
 * @Description: file content
 * @FilePath: \zm-data-platform-admin\src\layout\components\header.vue
-->
<template>
    <div class="header">
        <div class="logo">
            <span class="blue">ZEE</span>
            <span class="orange">MR</span>
            <span class="small">数据平台</span>
        </div>
        <div class="flex" @click.stop="loginShow = true">
            <span class="account">{{ user }}</span>
            <el-avatar slot="reference" :size="50" icon="el-icon-user-solid"></el-avatar>
        </div>
        <div class="shadow" v-if="loginShow">
            <div class="box">
                <p>确认退出吗？</p>
                <div class="buttons">
                    <el-button class="mr20" @click="loginShow = false">取消</el-button>
                    <el-button type="primary" @click="loginOut">确认</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            user: localStorage.getItem('account') || false,
            loginShow: false,
        }
    },
    methods: {
        loginOut() {
            this.$store.dispatch('loginOut')
        },
    },
}
</script>

<style lang="scss">
.header {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.logo {
    font-size: 36px;
    text-align: center;
    .blue {
        background: -webkit-linear-gradient(360deg, #48a0bc 0%, #70f0ea 100%);

        -webkit-background-clip: text;

        -webkit-text-fill-color: transparent;
    }
    .orange {
        background: -webkit-linear-gradient(360deg, #fbe64f 0%, #ec698b 100%);

        -webkit-background-clip: text;

        -webkit-text-fill-color: transparent;
    }
    .small {
        font-size: 28px;
    }
}
.cursor {
    border-bottom: 1px solid #eee;
    display: flex;
    justify-content: end;
}
.flex {
    display: inline-flex;
    align-items: center;
    .account {
        padding-right: 20px;
    }
}
.shadow {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10000;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    .box {
        width: 20%;
        height: fit-content;
        background: #fff;
        border-radius: 10px;
        p {
            text-align: center;
            line-height: 100px;
        }
        .buttons {
            display: flex;
            justify-content: center;
            height: fit-content;
            margin-bottom: 30px;
        }
    }
}
</style>
