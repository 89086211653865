/*
 * @Author: Corn-czy
 * @Date: 2022-06-22 10:23:56
 * @Description: file content
 * @FilePath: \zm-data-platform-admin\src\main.js
 */

import store from '@/store/index' //Vuex
import ElementUI from 'element-ui'
import Vue from 'vue'
import App from './App.vue'
import router from './router'

import '@/permission'
import '@/utils/rem'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/css/main.scss'
import echarts from './assets/echarts/main'

import { message } from '@/utils/resetMessage'
console.log(message)
Vue.config.productionTip = false
ElementUI.Dialog.props.closeOnClickModal.default = false
//挂载
Vue.prototype.$Message = message
Vue.prototype.$echarts = echarts
Vue.use(ElementUI)
Vue.config.silent = true
new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app')
