/*
 * @Author: Corn-czy
 * @Date: 2022-06-22 10:23:56
 * @Description: file content
 * @FilePath: \zm-data-platform-admin\src\permission.js
 */

import router from './router'
import store from "./store/index"
import NProgress from 'nprogress' // progress bar
NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['/'] // no redirect whitelist

router.beforeEach(async (to, from, next) => {
    // start progress bar
    NProgress.start()

    // determine whether the user has logged in
    if (store.state.token != '' || sessionStorage.getItem("token")) {
        if (to.path === '/') {
            store.dispatch("loginOut")
        } else {
            next()
        }
    } else {
        /* has no token*/
        if (whiteList.indexOf(to.path) !== -1) {
            // in the free login whitelist, go directly
            next()
        } else {
            next(`/?redirect=${to.path}`)
            NProgress.done()
        }
    }
})

router.afterEach(() => {
    // finish progress bar
    NProgress.done()
})