<!--
 * @Author: Corn-czy
 * @Date: 2021-11-08 11:18:36
 * @Description: file content
 * @FilePath: \zm-data-platform-admin\src\layout\components\menu.vue
-->
<!-- menu -->
<template>
    <el-menu :default-active="this.$route.path" unique-opened router class="el-menu-vertical-demo">
        <el-menu-item v-for="(menus,idxM) in routerArr" :key="idxM" :index="menus.path">
            <i :class="menus.meta.icon"></i>
            <span slot="title">{{menus.meta.title}}</span>
        </el-menu-item>
    </el-menu>
</template>
<script >
export default {
    data () {
        return {
            routerArr: [],
            level: false,
            merchantEdition: localStorage.getItem("merchant_edition") || false,
        }
    },
    mounted () {
        this.getRouter()
    },
    methods: {
        getRouter () {
            this.routerArr = this.$router.options.routes.filter(item => {
                if (item.path != '/' && item.path != '*') {
                    return item
                }
            });
        }
    }
}
</script>
<style lang="scss" scoped>
::v-deep .el-menu-item.is-active {
    border-right: 2px solid;
}
.el-menu-vertical-demo {
    height: 100vh;
}
</style>