/*
 * @Author: Corn-czy
 * @Date: 2022-05-16 13:51:55
 * @Description: file content
 * @FilePath: \zm-data-platform-admin\src\api\login.js
 */
import request from "@/utils/request"

/**
 * 获取验证码
 */
export const captcha = (params) => {
    return request({
        url: '/login/captcha.json',
        method: 'get',
        params
    })
}
/**
 * 登录
 */
export const login = (data) => {
    return request({
        url: '/login/login.json',
        method: 'post',
        data
    })
}
/**
 * 子登录
 */
export const loginRam = (data) => {
    return request({
        url: '/login/ram.json',
        method: 'post',
        data
    })
}