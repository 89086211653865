/*
 * @Author: Corn-czy
 * @Date: 2022-05-11 18:26:35
 * @Description: file content
 * @FilePath: \zm-data-platform-admin\src\utils\rem.js
 */
//rem.js
const baseSize = 16
// 设置 rem 函数
function setRem () {
    // 当前页面宽度相对于 1440宽的缩放比例，可根据设计图需要修改。
    console.log(document.documentElement.clientHeight)
    const scale = document.documentElement.clientWidth / 1920
    // 设置页面根节点字体大小（“Math.min(scale, 2)” 指最高放大比例为2，可根据实际业务需求调整）
    document.documentElement.style.fontSize = baseSize * Math.min(scale, 2) + 'px'
}
// 初始化
setRem()
// 改变窗口大小时重新设置 rem
window.onresize = function () {
    setRem()
}