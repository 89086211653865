<!--
 * @Author: Corn-czy
 * @Date: 2021-11-07 23:50:15
 * @Description: file content
 * @FilePath: \zm-data-platform-admin\src\layout\index.vue
-->
<template>
    <el-container>
        <el-header class="el-headers">
            <Header />
        </el-header>
        <el-container>
            <el-aside width="10%">
                <Menus />
            </el-aside>
            <el-main class="mainBg">
                <div class="infinite-list-wrapper" style="overflow: auto">
                    <router-view />
                </div>
            </el-main>
        </el-container>
    </el-container>
</template>
<script>
import Header from './components/header.vue'
import Menus from './components/menu.vue'
export default {
    components: {
        Header,
        Menus,
    },
    data() {
        return {
            levelList: [],
        }
    },
    watch: {
        $route() {
            this.getBreadcrumb()
        },
    },
    mounted() {
        this.getBreadcrumb()
    },
    methods: {
        getBreadcrumb() {
            this.levelList = this.$route.matched
        },
    },
}
</script>
<style lang="scss" scoped>
.el-headers {
    height: 70px !important;
    z-index: 100000 !important;
    background: #fff !important;
}

::v-deep .el-breadcrumb {
    padding-bottom: 14px;
}

.mainBg {
    background: #f4f7fe;
    height: 93vh;
}

.infinite-list-wrapper {
    height: 100%;
}
</style>
