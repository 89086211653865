/*
 * @Author: Corn-czy
 * @Date: 2022-05-10 14:46:35
 * @Description: file content
 * @FilePath: \zm-data-platform-admin\src\utils\request.js
 */
import axios from 'axios'
import {
    message
} from '@/utils/resetMessage';
import router from "../router";

const service = axios.create({
    baseURL: process.env.VUE_APP_URL,
    timeout: 5000
})
service.interceptors.request.use(
    config => {
        let token = sessionStorage.getItem("token")
        if (token) {
            config.headers['Authorization'] = token
        }
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

service.interceptors.response.use(
    response => {
        const res = response.data;
        if (res.code == 8006) {
            localStorage.removeItem('token');
            router.replace({
                path: '/',
            })
        }
        return res

    },
    error => {
        console.log('err' + error)
        message({
            message: error.response.data.msg,
            type: 'error',
            duration: 3 * 1000,
            customClass: "messageMy"
        })
        return error.response.data
    }
)

export default service
